export function INIT_SCROLLSPY_ANCHOR_NAV(ScrollSpy) {
    const ANCHOR_NAV = document.querySelector('#anchorNavigation');
    if (!ANCHOR_NAV) return;

    new ScrollSpy(document.body, {
        target: ANCHOR_NAV,
        rootMargin: `-25% 0px -25% 0px`
    });

    window.addEventListener('activate.bs.scrollspy', () => {
        const activeItemOffset = ANCHOR_NAV.querySelector('.nav-link.active').offsetLeft;
        const headlineWidth = ANCHOR_NAV.querySelector('.nav-link--headline').clientWidth;

        ANCHOR_NAV.scroll({
            left: activeItemOffset - (headlineWidth * 1.25),
            top: 0,
            behavior: 'smooth'
        });
    });
}

export function INIT_SCROLLSPY_RECRUITING_NAV(ScrollSpy) {
    const RECRUITING_NAV = document.querySelector('.is--landingpage-recruiting-austria .navbar-nav.navbar--bottom');
    if (!RECRUITING_NAV) return;

    new ScrollSpy(document.body, {
        target: RECRUITING_NAV,
        rootMargin: `-25% 0px -25% 0px`
    });
}


