function navIsScrolled(anchorNav) {
    const NAV = anchorNav.querySelector('.nav');
    if (NAV) return NAV.getBoundingClientRect().x < 16;
    else return false;
}

export function INIT_ANCHOR_NAV(anchorNav, menuToggleCheckbox) {
    const ANCHOR_NAV = document.querySelector('#anchorNavigation')
    const MENU_TOGGLE_CHECKBOX = document.getElementById('menuToggleCheckbox')

    if (!ANCHOR_NAV) return;

    ANCHOR_NAV.querySelectorAll('.nav-link').forEach(link => {
        link.addEventListener('click', () => {
            if (MENU_TOGGLE_CHECKBOX.checked) MENU_TOGGLE_CHECKBOX.checked = false;
        });
    });

    ANCHOR_NAV.addEventListener('scroll', () => {
        if (navIsScrolled(ANCHOR_NAV)) ANCHOR_NAV.setAttribute('data-scrolled', '');
        else ANCHOR_NAV.removeAttribute('data-scrolled');
    });
}
