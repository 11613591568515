import Swiper from "swiper";
import {Navigation, FreeMode, Mousewheel, Pagination, Autoplay, Parallax} from 'swiper/modules';

export function INIT_SWIPER() {


    document.querySelectorAll('[data-swiper]').forEach(swiperElement => {
        const swiperName = swiperElement.getAttribute('data-swiper'),
            swiperInstance = new Swiper(swiperElement, {
                direction: 'horizontal',
                speed: 1000,
                modules: [Navigation, Mousewheel, FreeMode, Pagination],
                spaceBetween: 32,
                updateOnResize: true,
                watchSlidesProgress: true,

                freeMode: {
                    enabled: true,
                    sticky: false,
                    momentumBounce: false
                },

                mousewheel: {
                    enabled: true,
                    forceToAxis: true
                },

                pagination: {
                    enabled: true,
                    clickable: true,
                    el: `[data-swiper-pagination="${swiperName}"]`,
                    bulletActiveClass: 'is--active',
                    bulletClass: 'bullet',
                    type: 'bullets',
                    renderBullet: (index, className) => {
                        return '<i class="' + className + '"></i>';
                    }
                },

                navigation: {
                    prevEl: `[data-swiper-navigation-button="${swiperName}"].swiper-button-prev`,
                    nextEl: `[data-swiper-navigation-button="${swiperName}"].swiper-button-next`
                },

                breakpoints: {
                    1400: {
                        spaceBetween: 32,
                        slidesPerView: 3,
                    },
                    1200: {
                        spaceBetween: 16,
                        slidesPerView: 2.5,
                    },
                    992: {
                        spaceBetween: 16,
                        slidesPerView: 2.25,
                    },
                    768: {
                        spaceBetween: 16,
                        slidesPerView: 1.75,
                    },
                    576: {
                        spaceBetween: 16,
                        slidesPerView: 1.5
                    },
                    320: {
                        spaceBetween: 8,
                        slidesPerView: 1.1125
                    }
                },
            });
    })

}
