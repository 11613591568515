
export function INIT_HEADROOM(Headroom) {
    const HEADER = document.querySelector('header.header'),
        HEADROOM = new Headroom(HEADER, {
            offset: 0,
            tolerance: {
                up: 5,
                down: 20
            }
        });

    HEADROOM.init();
}
