function formIsActive(searchForm) {
    return !!searchForm.getAttribute('data-active');
}

export function INIT_SEARCH_HEADER() {
    const SEARCH_FORM = document.querySelector('[data-search-header]'),
        FORM_INPUT = SEARCH_FORM ? SEARCH_FORM.querySelector('#searchHeader') : false;

    if(!FORM_INPUT) return;

    SEARCH_FORM.addEventListener('submit', e => {
        if (!formIsActive(SEARCH_FORM)) e.preventDefault();
    });

    SEARCH_FORM.querySelector('button[type="submit"]').addEventListener('click', e => {

        if (!formIsActive(SEARCH_FORM)) {
            e.preventDefault();
            SEARCH_FORM.setAttribute('data-active', 'true');
            FORM_INPUT.focus();
        } else {
            if (!FORM_INPUT.value || FORM_INPUT.value === '') {
                e.preventDefault();
                SEARCH_FORM.removeAttribute('data-active');
            }
        }
    })

    document.addEventListener('click', e => {
        const FORM_HAS_VALUE = !!FORM_INPUT.value,
            NO_CLICK_TARGET = e.composedPath().indexOf(SEARCH_FORM) === -1;

        if (NO_CLICK_TARGET && !FORM_HAS_VALUE) SEARCH_FORM.removeAttribute('data-active');
    })

}
